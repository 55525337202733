
import { Route, Routes } from "react-router-dom";
import { AuthGuard } from "./authGuard";
import { TwoFactorGuard } from "./TwoFactorGuard";

import { lazy, Suspense } from 'react';
import { ErrorBoundary } from "@sentry/react";
import { ErrorBoundaryAlert } from "@/view/components/custom/error-message";
import { useUser } from "../stories/user";
import Loading from "@/view/components/custom/loading";

const Login = lazy(() => import("@/view/pages/Auth/Login"));
const VerifyCode = lazy(() => import("@/view/pages/Auth/VerifyCode"));
const ConfirmnEmail = lazy(() => import("@/view/pages/Auth/ConfirmEmail"));
const Enable2Fa = lazy(() => import("@/view/pages/Auth/Enable2Fa"));
const NotFound = lazy(() => import("@/view/pages/NotFound"));
const DashboardEcompleto = lazy(() => import("@/view/pages/DashboardEcompleto"));
const DashboardLayout = lazy(() => import("@/view/layouts/DashboardLayout"));
const AuthLayout = lazy(() => import("@/view/layouts/AuthLayout"));
const Dashboard = lazy(() => import("@/view/pages/Dashboard"));

export function Router() {
  const { storeId } = useUser();

  return (
    <ErrorBoundary fallback={<ErrorBoundaryAlert />}>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route element={<AuthGuard isPrivate={false} />}>
            <Route element={<AuthLayout />}>
              <Route element={<Login />} path="/login" />
            </Route>
          </Route>
          <Route element={<TwoFactorGuard />}>
            <Route element={<AuthLayout />}>
              <Route element={<VerifyCode />} path="/code-authentication" />
            </Route>

            <Route path="/enable-2fa" element={<Enable2Fa />} />
            <Route element={<ConfirmnEmail />} path="/confirm-email" />
          </Route>
          <Route element={<AuthGuard isPrivate={true} />}>
            <Route element={<DashboardLayout />}>
              <Route element={storeId === 4 ? <DashboardEcompleto /> : <Dashboard />} path="/" />
            </Route>
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
}